import * as Types from '../../graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

/*
* This was automatically generated on by @graphql-codegen.
* DO NOT MANUALLY EDIT !!!
* Please refer to https://github.com/wistia/wistia/blob/main/frontend/graphql/README.md for more information.
* Last Updated: Fri Apr 11 2025 16:38:04 GMT+0000 (Coordinated Universal Time)
*/

const defaultOptions = {} as const;
export type GetDatadogDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetDatadogDataQuery = { __typename?: 'Query', currentAccount?: { __typename?: 'Account', id: string, displayName: string, key: string, createdAt: string, plan?: { __typename?: 'Plan', id: string, name: string } | null | undefined } | null | undefined, currentUser?: { __typename?: 'User', id: string, name?: string | null | undefined, email: string } | null | undefined, appMetadata: { __typename?: 'AppMetadata', sha: string } };


export const GetDatadogDataDocument = gql`
    query getDatadogData {
  currentAccount {
    id
    displayName
    key
    createdAt
    plan {
      id
      name
    }
  }
  currentUser {
    id
    name
    email
  }
  appMetadata {
    sha
  }
}
    `;

/**
 * __useGetDatadogDataQuery__
 *
 * To run a query within a React component, call `useGetDatadogDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDatadogDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDatadogDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDatadogDataQuery(baseOptions?: Apollo.QueryHookOptions<GetDatadogDataQuery, GetDatadogDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDatadogDataQuery, GetDatadogDataQueryVariables>(GetDatadogDataDocument, options);
      }
export function useGetDatadogDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDatadogDataQuery, GetDatadogDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDatadogDataQuery, GetDatadogDataQueryVariables>(GetDatadogDataDocument, options);
        }
export function useGetDatadogDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetDatadogDataQuery, GetDatadogDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDatadogDataQuery, GetDatadogDataQueryVariables>(GetDatadogDataDocument, options);
        }
export type GetDatadogDataQueryHookResult = ReturnType<typeof useGetDatadogDataQuery>;
export type GetDatadogDataLazyQueryHookResult = ReturnType<typeof useGetDatadogDataLazyQuery>;
export type GetDatadogDataSuspenseQueryHookResult = ReturnType<typeof useGetDatadogDataSuspenseQuery>;
export type GetDatadogDataQueryResult = Apollo.QueryResult<GetDatadogDataQuery, GetDatadogDataQueryVariables>;